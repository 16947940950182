import React, { useEffect, useState } from 'react';
import { Upload, Button, Select, message, Tabs, Input, DatePicker } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import axios from 'axios';
import dayjs from 'dayjs';

const { Option } = Select;
const { TabPane } = Tabs;
const { RangePicker } = DatePicker;

const FileUploadList = () => {
    const [companies, setCompanies] = useState([]);
    const [selectedCompany, setSelectedCompany] = useState(null);
    const [selectedFileType, setSelectedFileType] = useState(null);
    const [uploading, setUploading] = useState(false);
    const [uploadStatus, setUploadStatus] = useState(null);
    const [fileData, setFileData] = useState([]);
    const [dateRange, setDateRange] = useState(null); // Tarih aralığı için state
    const [personel, setPersonel] = useState('');
    const [activeTab, setActiveTab] = useState('1'); // Aktif tab takibi

    const user = JSON.parse(localStorage.authdata).username;

    useEffect(() => {
        axios.post('/companyList', { method: "companyList", processor: "company" })
            .then(response => {
                if (response.data.data && Array.isArray(response.data.data)) {
                    setCompanies(response.data.data);
                }
            })
            .catch(error => {
                console.error('Company listesi alınırken hata oluştu:', error);
            });

        const data = {
            username: user,
        };

        axios.post('/getFiles', { method: "getFiles", processor: "files", data })
            .then(response => {
                if (response.data.data && Array.isArray(response.data.data)) {
                    setFileData(response.data.data);
                }
            })
            .catch(error => {
                console.error('Dosya listesi alınırken hata oluştu:', error);
            });
    }, [user]);

    const handleUpload = ({ file, onSuccess, onError }) => {
        if ((selectedCompany && activeTab === '1') || (!personel.trim() && activeTab === '2') || !selectedFileType) {
            message.error('Lütfen tüm seçimleri yapın');
            return;
        }

        setUploading(true);

        const formData = new FormData();
        formData.append('file', file);
        formData.append('company', selectedCompany);
        formData.append('fileType', selectedFileType);
        formData.append('username', user);
        formData.append('pathName', file.name.toString());

        if (selectedFileType === 'Type2' && dateRange) {
            formData.append('startDate', dateRange[0].valueOf()); // Milliseconds since epoch
            formData.append('endDate', dateRange[1].valueOf());   // Milliseconds since epoch
        } else {
            formData.append('startDate', 0);
            formData.append('endDate', 0);
        }
        formData.append('personel', personel);

        axios.post('/uploadTemp', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })
            .then(response => {
                if (response.status === 200) {
                    setUploadStatus('success');
                    message.success('Dosya başarıyla yüklendi');
                    onSuccess('OK');
                    setFileData(prevData => [...prevData, response.data.newFile]);
                } else {
                    setUploadStatus('error');
                    message.error('Dosya yüklenirken hata oluştu');
                    onError('Error');
                }
            })
            .catch(error => {
                setUploadStatus('error');
                console.error('Dosya yüklenirken hata oluştu:', error);
                message.error('Dosya yüklenirken hata oluştu');
                onError('Error');
            })
            .finally(() => {
                setUploading(false);
            });
    };

    const handleCompanyChange = (value) => {
        setSelectedCompany(value == null ? null : value);
        setUploadStatus(null);
    };

    const handleFileTypeChange = (value) => {
        setSelectedFileType(value);
        setUploadStatus(null);
    };

    const handleDateChange = (dates) => {
        setDateRange(dates);
    };

    const resetCompanyTab = () => {
        setSelectedFileType(null);
        setSelectedCompany(null);
    };

    const resetPersonelTab = () => {
        setPersonel('');
        setSelectedFileType(null);

    };

    const isButtonDisabled = () => {
        if (activeTab === '1') {
            return  !selectedFileType || uploading;
        } else {
            return !personel.trim() || !selectedFileType || uploading;
        }
    };

    return (
        <div>
            <Tabs
                defaultActiveKey="1"
                onChange={(key) => {
                    setActiveTab(key);
                    if (key === "1") {
                        resetCompanyTab();
                        resetPersonelTab();
                    } else if (key === "2") {
                        resetCompanyTab();
                        resetPersonelTab();
                    }
                }}
            >
                <TabPane tab="Kurum Seçimi" key="1">

                </TabPane>
                <TabPane tab="Personel Seçimi" key="2">
                    <Input
                        placeholder="Ad Soyad"
                        style={{ width: '100%', marginBottom: '8px' }}
                        value={personel}
                        onChange={(e) => setPersonel(e.target.value)}
                    />
                </TabPane>
            </Tabs>

            <Select
                style={{ width: '100%', marginBottom: 16 }}
                placeholder="Belge Türü Seçin"
                onChange={handleFileTypeChange}
                value={selectedFileType}
            >
                <Option value="Type1">Nüfus Cüzdanı Fotokopisi</Option>
                <Option value="Type2">Geçici Görevlendirme Formu</Option>
                <Option value="Type3">İkametgah Kaydı</Option>
                <Option value="Type4">Adli Sicil Kaydı</Option>
                <Option value="Type5">SGK İşe Giriş Bildirgesi, Veya İşveren/İşçi Bağkur Belgesi</Option>
                <Option value="Type6">Güncel Aylık Hizmet Belgesi</Option>
                <Option value="Type7">Diploma ve/veya Mesleki Yeterlilik Belgesi</Option>
                <Option value="Type8">Ehliyet, Operatör Belgesi, SRC Belgesi ve Psikoteknik Belgesi</Option>
                <Option value="Type9">Sağık Raporu</Option>
                <Option value="Type10">Temel ISG Eğitim Sertifikası</Option>
                <Option value="Type11">KKD Zimmet Tutanakları Fazla Çalışma Onay Belgesi</Option>
                <Option value="Type12">Jandarma Bildirimi İçin Jandarma Bildirim Formu</Option>
                <Option value="Type13">Gece Çalışma Yapacak Personellerin, Gece Çalışmayı Kabul Ettiklerine Dair Belge</Option>
                <Option value="Type14">Beden İşçisi Statüsünde Çalışacak Personellerin, MYK Gerektiren İşlerde Çalıştırılmayacağına Dair Taahhüt</Option>
                <Option value="Type15">İsg Takip Üzerinde Yapılan İşyeri Hekimi Ve İş Güvenliği Uzmanı Atamaları</Option>
                <Option value="Type16">Yasal Kapsamda Hazırlanmış Risk Analizleri</Option>
                <Option value="Type17">Yasal Mevzuat Kapsamında Aynı Firma Çalışanlarında 10 Kişiye 1 İlkyardımcı Eğitimi Olan Personel.</Option>
            </Select>

            {selectedFileType === 'Type2' && (
                <RangePicker
                    style={{ width: '100%', marginBottom: 16 }}
                    onChange={handleDateChange}
                    value={dateRange}
                    placeholder={['Başlangıç Tarihi', 'Bitiş Tarihi']}
                />
            )}

            <Upload
                customRequest={handleUpload}
                showUploadList={{
                    showRemoveIcon: false,
                    showPreviewIcon: false,
                }}
            >
                <Button
                    icon={<UploadOutlined />}
                    style={{ width: '100%', marginLeft: 'auto', marginRight: 'auto' }}
                    disabled={isButtonDisabled()}
                >
                    {uploading ? 'Loading...' : 'File Upload'}
                </Button>
            </Upload>
        </div>
    );
};

export default FileUploadList;
