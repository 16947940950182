import React, { useEffect, useState } from "react";
import { Table, Button, message, Space, Tooltip, Modal, Form, Input } from "antd";
import axios from "axios";
import {DeleteOutlined, EditOutlined, PlusCircleOutlined, UploadOutlined} from "@ant-design/icons";
import CompanyUpdate from "./Updates/CompanyUpdate";
import dayjs from "dayjs";

const CompanyDefinitionsList = () => {
    const [selectedRow, setSelectedRow] = useState(null);
    const [tableData, setTableData] = useState([]);
    const [modal1Open, setModal1Open] = useState(false);
    const [modal2Open, setModal2Open] = useState(false);
    const [modal3Open, setModal3Open] = useState(false);
    const [form] = Form.useForm();

    const user = JSON.parse(localStorage.authdata).username;

    const loadTableData = () => {
        axios.post("/companyList", { method: "companyList", processor: "company" })
            .then((response) => {
                if (response.data.data && Array.isArray(response.data.data)) {
                    setTableData(response.data.data);
                }
            })
            .catch((error) => {
                console.error("Veri alınırken bir hata oluştu: ", error);
            });
    };

    useEffect(() => {
        loadTableData();
    }, []);

    const onSearch = (searchValue) => {
        const searchData = {
            search: searchValue,
            operator: user,
        };

        axios.post("/companySearch", {
            method: "companySearch",
            processor: "company",
            data: searchData,
        })
            .then((response) => {
                if (response.data.data && Array.isArray(response.data.data)) {
                    setTableData(response.data.data);
                }
            })
            .catch((error) => {
                console.error("Veri alınırken bir hata oluştu: ", error);
            });
    };

    const sendRequestToCompanyAdd = (data) => {
        const requestData = {
            name: data.name,
            code: data.code,
            operator: user,
        };

        axios.post("/companyAdd", { method: "companyAdd", processor: "company", data: requestData })
            .then((response) => {
                if (response.status === 200) {
                    console.log("Kurum başarıyla eklendi.");
                    setModal1Open(false);
                    message.success('Kurum başarıyla eklendi.');
                    loadTableData();
                } else {
                    console.error("Kurum eklenirken bir hata oluştu:", response.data.error);
                    setModal1Open(false);
                    message.error('Kurum eklenirken bir hata oluştu');
                }
            })
            .catch((error) => {
                console.error("İstek gönderilirken bir hata oluştu: ", error);
                setModal1Open(false);
                message.error('İstek gönderilirken bir hata oluştu');
            });
    };

    const sendRequestToCompanyUpdate = (data) => {
        const requestData = {
            name: data.name,
            id: selectedRow.id,
            code : data.code,
            operator: user,
        };

        axios.post("/companyUpdate", { method: "companyUpdate", processor: "company", data: requestData })
            .then((response) => {
                if (response.status === 200) {
                    console.log("Kurum başarıyla güncellendi.");
                    setModal2Open(false);
                    message.success('Kurum başarıyla güncellendi');
                    loadTableData();
                } else {
                    console.error("Kurum güncellenirken bir hata oluştu:", response.data.error);
                    setModal2Open(false);
                    message.error('Kurum güncellenirken bir hata oluştu');
                }
            })
            .catch((error) => {
                console.error("İstek gönderilirken bir hata oluştu: ", error);
                setModal2Open(false);
                message.error('İstek gönderilirken bir hata oluştu');
            });
    };

    const sendRequestForDelete = () => {
        const requestData = {
            id: selectedRow.id,
            operator: user,
        };

        axios.post("/companyDelete", { method: "companyDelete", processor: "company", data: requestData })
            .then((response) => {
                if (response.status === 200) {
                    console.log("Kurum başarıyla silindi.");
                    setModal3Open(false);
                    message.success('Kurum başarıyla silindi.');
                    loadTableData();
                    setSelectedRow(null);
                } else {
                    console.error("Kurum silinirken bir hata oluştu:", response.data.error);
                    setModal3Open(false);
                    message.error('Kurum silinirken bir hata oluştu');
                }
            })
            .catch((error) => {
                console.error("İstek gönderilirken bir hata oluştu: ", error);
                setModal3Open(false);
                message.error('İstek gönderilirken bir hata oluştu');
            });
    };

    const columns = [
        {
            key: "name",
            title: "Kurum Adı",
            dataIndex: "name",
        },
        {
            key: "createDate",
            title: "Oluşturma Tarihi",
            dataIndex: "createDate",
            render: (text) => dayjs(text).format('DD.MM.YYYY HH:mm:ss'),
        },
        {
            key: "operator",
            title: "Operator",
            dataIndex: "operator",
        },
        {
            title: 'İşlemler',
            key: 'action',
            render: (_, record) => (
                <Space size="middle">
                    <Tooltip placement="top" title="Güncelle">
                        <Button
                            type="primary"
                            icon={<EditOutlined />}
                            onClick={() => setModal2Open(true)}
                        />
                    </Tooltip>

                    <Tooltip placement="top" title="Sil">
                        <Button
                            type="primary"
                            icon={<DeleteOutlined />}
                            onClick={() => setModal3Open(true)}
                        />
                    </Tooltip>
                </Space>
            ),
        },
    ];

    const rowSelection = {
        type: "radio",
        onChange: (_, selectedRows) => {
            setSelectedRow(selectedRows[0]);
        },
    };

    return (
        <>
            <Modal
                title="Ekle"
                style={{ top: 20 }}
                open={modal1Open}
                onOk={() => {
                    form.validateFields()
                        .then((values) => {
                            sendRequestToCompanyAdd(values);
                            form.resetFields();
                        })
                        .catch((errorInfo) => {
                            console.error('Form doğrulama hatası:', errorInfo);
                        });
                }}
                onCancel={() => setModal1Open(false)}
            >
                <CompanyAdd form={form} />
            </Modal>

            <Modal
                title="Güncelle"
                style={{ top: 20 }}
                open={selectedRow && modal2Open}
                onOk={() => {
                    form.validateFields()
                        .then((values) => {
                            sendRequestToCompanyUpdate(values);
                            form.resetFields();
                        })
                        .catch((errorInfo) => {
                            console.error('Form doğrulama hatası:', errorInfo);
                        });
                }}
                onCancel={() => setModal2Open(false)}
            >
                {selectedRow && <CompanyUpdate selectedRow={selectedRow} form={form} />}
            </Modal>

            <Modal title="Silmek istediğinize eminmisiniz?" open={modal3Open} onOk={sendRequestForDelete} onCancel={() => setModal3Open(false)} />

            <div>
                <div style={{ textAlign: "left", display: "flex", alignItems: "center" }}>
                    <Tooltip placement="rightTop" title="Ekle">
                        <Button
                            type="primary"
                            icon={<PlusCircleOutlined />}
                            onClick={() => setModal1Open(true)}
                            style={{ marginRight: '5px' }}
                        >
                            Kurum Ekle
                        </Button>
                    </Tooltip>
                </div>
                <Table
                    rowSelection={rowSelection}
                    columns={columns}
                    dataSource={tableData.map((item) => ({ ...item, key: item.id }))}
                    onRow={(record) => ({
                        onClick: () => setSelectedRow(record),
                    })}
                    scroll={{ x: 1000 }}
                />
            </div>
        </>
    );
};

const CompanyAdd = ({ form }) => {
    return (
        <Form form={form} layout="vertical">
            <Form.Item label="Kodu" name="code" rules={[{ required: true, message: 'Zorunlu alan *' }]}>
                <Input placeholder="Kod" />
            </Form.Item>
            <Form.Item label="Adı" name="name" rules={[{ required: true, message: 'Zorunlu alan *' }]}>
                <Input placeholder="Adı" />
            </Form.Item>
        </Form>
    );
};

export default CompanyDefinitionsList;
