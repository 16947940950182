import React, { useState, useEffect, useContext } from "react";
import {
    DashboardOutlined,
    LogoutOutlined,
    UserOutlined,
} from "@ant-design/icons";
import { Breadcrumb, Button, Col, Layout, Menu, Row, Statistic, Card, Timeline } from "antd";
import { useNavigate } from "react-router-dom";
import { MainContext } from "../../context";
import FileManagement from "../DataList/FileManagement";
import FileManagementUser from "../DataList/FileManagementUser";
import CountUp from "react-countup";
import CompanyDefinitionsList from "../DataList/CompanyDefinitionsList";
import FileUploadList from "../DataList/FileUploadList";
import UserDefinitionsList from "../DataList/UserDefinitionsList";

const { Header, Content, Sider } = Layout;

const Dashboard = () => {
    const formatter = (value) => <CountUp end={value} separator="," />;
    const [activeTab, setActiveTab] = useState(null);

    const navigate = useNavigate();
    const [value, dispatch] = useContext(MainContext);
    const [selectedMenuItem, setSelectedMenuItem] = useState("1");
    const [content, setContent] = useState("dashboard");
    const [selectedContent, setSelectedContent] = useState("");

    // Kullanıcı rolünü localStorage'dan alıyoruz
    const userRole = JSON.parse(localStorage.authdata).userRole;

    const items2 = [
        userRole === "ROLE_ADMIN" && {
            key: "sub2",
            icon: <UserOutlined />,
            label: "Tanımlar",
            children: [
                {
                    key: "sub10",
                    label: "Kurum Tanımları",
                    content: userRole === "ROLE_ADMIN" ? <CompanyDefinitionsList /> : null,
                    style: { textAlign: "left", fontSize: 11 },
                },
                {
                    key: "sub11",
                    label: "Kullanıcı Tanımları",
                    content: userRole === "ROLE_ADMIN" ? <UserDefinitionsList /> : null,
                    style: { textAlign: "left", fontSize: 11 },
                },
            ].filter(item => item),
        },
        {
            key: "sub3",
            icon: <UserOutlined />,
            label: "Formlar",
            children: [
                userRole === "ROLE_ADMIN" && {
                    key: "sub12",
                    label: "Yüklenen Formlar",
                    content: userRole === "ROLE_ADMIN" ? <FileManagement /> : null,
                    style: { textAlign: "left", fontSize: 11 },
                },
                {
                    key: "sub14",
                    label: "Form Yükle",
                    content: <FileManagementUser />,
                    style: { textAlign: "left", fontSize: 11 },
                },
            ].filter(item => item),
        }
    ].filter(item => item);

    const handleMenuItemClick = (key, content) => {
        if (!key || !content) return;
        setSelectedMenuItem(key);
        setContent(content);
        setSelectedContent(content);
    };

    const handleMenuClick = ({ key }) => {
        const selectedItem = items2
            .map(item => item?.children)
            .flat()
            .find(item => item?.key === key);

        if (selectedItem) {
            handleMenuItemClick(key, selectedItem.content);
        }
    };

    const checkDashboard = (key) => {
        if(key === "ROLE_ADMIN"){
            return <FileManagement/>
        }else {
            return <FileManagementUser/>
        }
    };

    const dashboardButton = () => {
        setSelectedMenuItem("1");
        setContent(
            checkDashboard(userRole)
    );
        setSelectedMenuItem("Dashboard");
    };

    const logout = () => {
        dispatch({ type: "SIGN_OUT" });
        localStorage.removeItem("authdata");
        navigate("/");
    };

    useEffect(() => {
        dashboardButton();
    }, []);

    return (
        <Layout>
            <Header style={{display: "flex", alignItems: "center"}}>
                <div className="demo-logo"/>
                <Menu theme="dark" mode="horizontal" defaultSelectedKeys={["1"]} items={[]}/>
                <Button type="primary" onClick={dashboardButton}>
                    <DashboardOutlined/> Anasayfa
                </Button>
                {/* Boşluk bırakmak için flex: 1 kullanıldı */}
                <div style={{flex: 1, textAlign: 'center', fontWeight: 'bold', fontSize: '20px'}}>
                    <a href={window.location.href} style={{color: 'white'}}>SEHOFFICE</a>
                </div>
                <Button type="primary" onClick={logout} style={{marginLeft: 'auto'}}>
                    <LogoutOutlined/> Çıkış
                </Button>
            </Header>
            <Layout>
                <Sider width={200} style={{background: "#f0f0f0"}}>
                    <Menu
                        mode="inline"
                        defaultSelectedKeys={[selectedMenuItem]}
                        defaultOpenKeys={["sub1"]}
                        style={{height: "auto", borderRight: 0, background: "#f0f0f0",}}
                        items={items2}
                        onClick={handleMenuClick}
                    />
                </Sider>
                <Layout style={{padding: "0 24px 24px"}}>
                    <Breadcrumb separator=">">
                        <Breadcrumb.Item href="/">{}</Breadcrumb.Item>
                    </Breadcrumb>
                    <Content
                        style={{
                            padding: 24,
                            margin: 0,
                            minHeight: "700",
                            background: "#f0f0f0",
                        }}
                    >
                        {userRole !== "ROLE_ADMIN" && selectedContent === "sub10" ? (
                            <div>Erişiminiz yok.</div>
                        ) : (
                            content
                        )}
                    </Content>
                </Layout>
            </Layout>
        </Layout>
    );
};

export default Dashboard;
