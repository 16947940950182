import React, { useEffect, useState } from "react";
import { Table, Button, message, Space, Tooltip, Input, Modal, Select } from "antd";
import {CheckOutlined, ClockCircleOutlined, StopOutlined, UploadOutlined} from "@ant-design/icons";
import axios from "axios";
import dayjs from 'dayjs';
import FileUploadList from "./FileUploadList";

const { Option } = Select;

const FileManagementUser = () => {
    const [selectedRow, setSelectedRow] = useState(null);
    const [token] = useState(JSON.parse(localStorage.authdata).token);
    const [tableData, setTableData] = useState([]);
    const [companies, setCompanies] = useState([]);
    const [personnel, setPersonnel] = useState([]);
    const [fileTypes, setFileTypes] = useState([]); // FileType seçenekleri için state ekledim
    const [personel , setPersonel] = useState(null);

    const [selectedCompany, setSelectedCompany] = useState('all');
    const [selectedFileType, setSelectedFileType] = useState('all');

    const [modal3Open, setModal3Open] = useState(false);
    const [uploadModalVisible, setUploadModalVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [modalKey, setModalKey] = useState(0); // Key to force re-render the modal
    const user = JSON.parse(localStorage.authdata).username;

    useEffect(() => {
        fetchCompanies();
        fetchData();
    }, [token]);

    const fetchCompanies = () => {
        axios.post('/companyList', { method: "companyList", processor: "company" })
            .then(response => {
                if (response.data.data && Array.isArray(response.data.data)) {
                    setCompanies([{ id: 'all', name: 'all' }, ...response.data.data]);
                }
            })
            .catch((error) => {
                console.error("An error occurred while fetching company data: ", error);
            });
    };

    const fetchData = () => {
        axios.post('/getFiles', { method: "getFiles", processor: "files", data })
            .then(response => {
                if (response.data.data && Array.isArray(response.data.data)) {
                    setTableData(response.data.data);
                }
            })
            .catch(error => {
                console.error('Dosya listesi alınırken hata oluştu:', error);
            });
    };

    const data = {
        username: user,
    };

    const listData = () => {
        const data = {
            method: "getFiles",
            processor: "file",
            data
        };

        if (selectedCompany && selectedCompany !== 'all') {
            data.companyId = selectedCompany;
        }

        return data;
    };

    const handleFileTypeChange = (value) => {
        setSelectedFileType(value);
    };

    const handleCompanyChange = (value) => {
        setSelectedCompany(value);
    };

    const handleFilter = () => {
        fetchData();
    };

    const sendRequestForDownload = () => {
        const requestData = {
            id: selectedRow.id,
            operator: user,
        };

        setLoading(true);

        axios
            .post("/download", { method: "download", processor: "files", data: requestData })
            .then((response) => {
                if (response.status === 200) {
                    const path = response.data.data.data.path;
                    const downloadUrl = "http://79.110.51.253/home/isg/repo/";
                    window.open(downloadUrl, "_blank");
                    setModal3Open(false);
                    successMessage();
                } else {
                    errorMessage();
                }
            })
            .catch((error) => {
                console.error("An error occurred while sending the request: ", error);
                errorMessage();
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const columns = [
        {
            id: 3,
            title: "Belge Türü",
            dataIndex: "fileType",
            render: (fileType,item) => {
                if(item.fileType === 'Type1' ) {
                    return 'Nüfus Cüzdanı Fotokopisi';
                } else if(item.fileType === 'Type2'){
                    return 'Geçici Görevlendirme Formu';
                } else if(item.fileType === 'Type3'){
                    return 'İkametgah Kaydı';
                } else if(item.fileType === 'Type4'){
                    return 'Adli Sicil Kaydı';
                } else if(item.fileType === 'Type5'){
                    return 'SGK İşe Giriş Bildirgesi, Veya İşveren/İşçi Bağkur Belgesi';
                } else if(item.fileType === 'Type6'){
                    return 'Güncel Aylık Hizmet Belgesi';
                } else if(item.fileType === 'Type7'){
                    return 'Diploma ve/veya Mesleki Yeterlilik Belgesi';
                } else if(item.fileType === 'Type8'){
                    return 'Ehliyet, Operatör Belgesi, SRC Belgesi ve Psikoteknik Belgesi';
                } else if(item.fileType === 'Type9'){
                    return 'Sağık Raporu';
                } else if(item.fileType === 'Type10'){
                    return 'Temel ISG Eğitim Sertifikası';
                } else if(item.fileType === 'Type11'){
                    return 'KKD Zimmet Tutanakları Fazla Çalışma Onay Belgesi';
                } else if(item.fileType === 'Type12'){
                    return 'Jandarma Bildirimi İçin Jandarma Bildirim Formu';
                } else if(item.fileType === 'Type13'){
                    return 'Gece Çalışma Yapacak Personellerin, Gece Çalışmayı Kabul Ettiklerine Dair Belge';
                } else if(item.fileType === 'Type14'){
                    return 'Beden İşçisi Statüsünde Çalışacak Personellerin, MYK Gerektiren İşlerde Çalıştırılmayacağına Dair Taahhüt';
                } else if(item.fileType === 'Type15'){
                    return 'İsg Takip Üzerinde Yapılan İşyeri Hekimi Ve İş Güvenliği Uzmanı Atamaları';
                } else if(item.fileType === 'Type16'){
                    return 'Yasal Kapsamda Hazırlanmış Risk Analizleri';
                } else if(item.fileType === 'Type17'){
                    return 'Yasal Mevzuat Kapsamında Aynı Firma Çalışanlarında 10 Kişiye 1 İlkyardımcı Eğitimi Olan Personel';
                }
            },
        },
        {
            id: 4,
            title: "Kurum",
            dataIndex: "operator"
        },
        {
            id: 5,
            title: "Personel",
            dataIndex: "personel"
        },
        {
            id: 6,
            title: "Süresi Geçti",
            dataIndex: "timeout",
            render: (timeout) => timeout ? <CheckOutlined /> : "",
        },
        {
            id: 7,
            title: "Onay Durumu",
            dataIndex: "approved",
            render: (reject,item) => {
                if(item.reject === true ) {
                    return null;
                } else {
                    if (item.approved === null ) {
                        return <ClockCircleOutlined />;
                    } else if (item.approved === true) {
                        return <CheckOutlined />;
                    } else if(item.approved === false) {
                        return "";
                    }
                }
            },
        },
        {
            id: 8,
            title: "Ret Durumu",
            dataIndex: "reject",
            render: (reject,item) => {
                if (item.reject === null ) {
                    return "";
                } else if (item.reject === true) {
                    return <CheckOutlined />;
                } else {
                    return "";
                }
            },
        },
        {
            id: 9,
            title: "Ret Nedeni",
            dataIndex: "rejectDescription",
            render: (rejectDescription, item) => {
                if (item.reject === true) {
                    return rejectDescription;
                }
                return null;
            },
        },
        {
            id: 10,
            title: "Oluşturma Tarihi",
            dataIndex: "startDate",
            render: (text) => text !== null ? dayjs(text).format('DD.MM.YYYY HH:mm:ss') : "",
        },
        {
            id: 11,
            title: "Başlangıç Tarihi",
            dataIndex: "startDate",
            render: (text) => text !== null ? dayjs(text).format('DD.MM.YYYY HH:mm:ss') : "",
        },
        {
            id: 12,
            title: "Bitiş Tarihi",
            dataIndex: "endDate",
            render: (text) => text !== null ? dayjs(text).format('DD.MM.YYYY HH:mm:ss') : "",
        },
    ];

    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedRow(selectedRows[0]);
        },
    };

    const onTableRowClick = (record) => {
        setSelectedRow(record);
    };

    const handleOk = () => {
        sendRequestForDownload();
        setSelectedRow(null);
    };

    const handleCancel = () => {
        setSelectedRow(null);
        setModal3Open(false);
    };

    const handlePersonelChange = (e) => {
        setPersonel(e.target.value); // Personel input alanındaki değeri günceller
    };

    const successMessage = () => {
        message.success('Dosya indiriliyor.');
    };

    const errorMessage = () => {
        message.error('Dosya indirme işlemi sırasında bir hata oluştu');
    };

    const showUploadModal = () => {
        setModalKey(prevKey => prevKey + 1); // Reset the modal content by changing the key
        setUploadModalVisible(true);
    };

    const handleUploadModalCancel = () => {
        fetchData();
        setUploadModalVisible(false);
    };

    const rowClassName = (record) => {
        if (record.timeout) {
            return 'row-timeout'; // timeout true ise renklendir
        } else if (record.reject) {
            return 'row-reject'; // reject true ise farklı renklendir
        }else if(record.approved){
            return 'row-approved';
        }
        return ''; // Hiçbir duruma uymuyorsa, normal satır
    };

    return (
        <div>
            <div style={{textAlign: "left", display: "flex", alignItems: "center"}}>
                <Tooltip placement="rightTop" title="Yükle">
                    <Button
                        type="primary"
                        icon={<UploadOutlined/>}
                        onClick={showUploadModal}
                        style={{marginRight: '5px', marginBottom: '10px'}}
                    >
                        Belge Yükle
                    </Button>
                </Tooltip>
            </div>
            <Table
                rowSelection={{
                    type: "radio",
                    ...rowSelection,
                }}
                columns={columns}
                dataSource={tableData.map((item) => ({...item, key: item.id}))}
                onRow={(record) => ({
                    onClick: () => onTableRowClick(record),
                })}
                scroll={{x: 1000}}
                rowClassName={rowClassName}
                pagination={{
                    pageSize: 5, // Görüntülenen kayıt sayısını 5 ile limitler
                }}
                style={{width: 'auto', height: '600px'}}
            />

            {/* File Upload Modal */}
            <Modal
                title="Belge Yükle"
                visible={uploadModalVisible}
                onCancel={handleUploadModalCancel}
                footer={null}
                width={800}
                key={modalKey}
            >
                <FileUploadList key={modalKey}/>
            </Modal>
        </div>
    );
};

export default FileManagementUser;
