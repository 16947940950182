import axios from 'axios'


const axiosSetup =  () => {
    axios.interceptors.request.clear();
    axios.interceptors.response.clear();

    axios.interceptors.request.use(function (config) {
        // Do something before request is sent;
        const cors = require('cors');
        const url = "http://79.110.51.253:7222/rest-api"
        if (!config.url.includes("http://")) {
            config.url = url + config.url;
        }

         //console.log("token :"  + authToken.length)
        const localStorageStr = localStorage.getItem("auhtData");
        if(localStorageStr != null || localStorageStr != undefined) {
            const jsonLocalStorage = JSON.parse(localStorageStr);
            config.headers.set("Authorization",jsonLocalStorage.token);
            console.log("axios")
            console.log(jsonLocalStorage.token)
        }


        return config;
    }, function (error) {
        // Do something with request error
        return Promise.reject(error);
    });

// Add a response interceptor
    axios.interceptors.response.use(function (response) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        //console.log(response.request._url);
        return response;
    }, function (error) {
        //console.log(error.request._url);
        console.log("axiosInterceptorerr : "+error)
        // console.log("err "+error.errorMessage)
        if (error.response?.data) {
            return Promise.reject(error.response.data);
        } else if (error.response) {
            return Promise.reject(error.response);
        } else {
            return Promise.reject(error);
        }
    });
}

export default axiosSetup;
