import React, { useEffect } from 'react';
import { Form, Input, Tag } from 'antd';

const CompanyUpdate = ({ selectedRow, form }) => {
    useEffect(() => {
        if (selectedRow) {
            form.setFieldsValue({
                code: selectedRow.code,
                name: selectedRow.name,
                id: selectedRow.id,
            });
        }
    }, [selectedRow, form]);

    const onFinish = (values) => {
        console.log("Form verileri:", values);
    };

    return (
        <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
        >
            <Form.Item
                label="Kod"
                name="code"
                rules={[{ required: true, message: 'This field is required' }]}
            >
                <Input placeholder="Code" />
            </Form.Item>
            <Form.Item
                label="Adı"
                name="name"
                rules={[{ required: true, message: 'This field is required' }]}
            >
                <Input placeholder="Name" />
            </Form.Item>
        </Form>
    );
};

export default CompanyUpdate;
