import React, {useContext, useState} from 'react';
import { Button, Checkbox, Form, Input } from 'antd';
import axios from "axios";
import {useNavigate} from "react-router-dom";
import {MainContext} from "../../context";
import {jwtDecode} from "jwt-decode";

const onFinish = (values) => {
    console.log('Success:', values);
};

const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
};

const Login = (props) => {

    const navigate = useNavigate();
    const [username, setUsername] = useState();
    const [password, setPassword] = useState();
    const [isLoggedIn, setIsLoggedin] = useState(null);
    const [value, dispatch] = useContext(MainContext);
    const [token ,setToken] = useState();

    const loginClicked = (event) => {

        let status = ""
        if((null != username ) || (null != password)) {
            const data = {username: username, password: password};
            axios.post("/login", data).then((res) => {
                setIsLoggedin(true);
                loginState(res)

            }).catch(error =>{
                console.log("hata")
                setIsLoggedin(false);
            })
        }
    };

    function loginState(props) {
        console.log("userrole", jwtDecode(props.data.data).userRole)
        localStorage.setItem('authdata', JSON.stringify({
            signedIn: true,
            username: username,
            token : props.data.data,
            userRole: jwtDecode(props.data.data).userRole
        }));


        dispatch({
            "type": "SIGN_IN", payload: {
                signedIn: true,
                username: username,
                token:token,
                userRole: jwtDecode(props.data.data).userRole
            },
        });

        navigate("/")
    }

    return (
        <div className="login-container">
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <Form
            name="basic"
            labelCol={{
                span: 8,
            }}
            wrapperCol={{
                span: 16,
            }}
            style={{
                maxWidth: 600,
            }}
            initialValues={{
                remember: true,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
        >
            <Form.Item
                label="Kullanıcı Adı"
                name="username"
                rules={[
                    {
                        required: true,
                        message: 'Kullanıcı Adınızı Girin',
                        setUsername: username
                    },
                ]}
            >
                <Input onChange={e => setUsername(e.target.value)} />
            </Form.Item>

            <Form.Item
                label="Şifre"
                name="password"
                rules={[
                    {
                        required: true,
                        message: 'Şifrenizi girin',
                        setUsername : password
                    },
                ]}
            >
                <Input.Password onChange={e => setPassword(e.target.value)}/>
            </Form.Item>

            <Form.Item
                name="remember"
                valuePropName="checked"
                wrapperCol={{
                    offset: 8,
                    span: 16,
                }}
            >
                <Checkbox>Beni Hatırla</Checkbox>
            </Form.Item>

            {isLoggedIn === false && ( // isLoggedIn false ise hata mesajını göster
                <Form.Item
                    wrapperCol={{
                        offset: 8,
                        span: 16,
                    }}
                >
                    <p style={{ color: 'red' }}>Incorrect username or password. Please try again.</p>
                </Form.Item>
            )}

            <Form.Item
                wrapperCol={{
                    offset: 8,
                    span: 16,
                }}
            >
                <Button color="default" variant="outlined"  htmlType="submit" onClick={loginClicked}>
                    Giriş
                </Button>
                {/*<Button type="text" htmlType="forgotPass" onClick={() => navigate("/forgot")} color> Şifremi Unuttum </Button>*/}

            </Form.Item>
        </Form>
    </div>
        </div>
);
}

export default Login;
