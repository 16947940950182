class AuthenticationService{
    registerSuccessfullLogin(username,password){
      localStorage.setItem('authenticatedUser',username);

    };
    logout(){
        localStorage.removeItem('authenticatedUser');
    }

    isUserLoggedIn(){
        let user = localStorage.getItem('authenticatedUser');
        return user!==null;
    }
}

export default new AuthenticationService();
