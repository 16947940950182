import React, { useEffect, useState } from "react";
import { Table, Button, message, Space, Tooltip, Switch } from "antd";
import axios from "axios";
import { Modal, Form, Input } from 'antd';
import { DeleteOutlined, EditOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import dayjs from "dayjs";

const UserDefinitionsList = () => {
    const [selectionType] = useState("radio");
    const [selectedRow, setSelectedRow] = useState(null);
    const [token] = useState(JSON.parse(localStorage.authdata).token);
    const [tableData, setTableData] = useState([]);
    const [modal1Open, setModal1Open] = useState(false); // Kullanıcı ekleme/güncelleme modal
    const [modal3Open, setModal3Open] = useState(false); // Kullanıcı silme modal
    const [isUpdateMode, setIsUpdateMode] = useState(false); // Ek mi güncelleme mi belirlemek için
    const [form] = Form.useForm();
    const { Search } = Input;
    const user = JSON.parse(localStorage.authdata).username;

    const listData = {
        method: "userList",
        processor: "user",
        operator: user
    };

    const onSearch = (searchValue) => {
        if (searchValue) {
            const searchData = {
                search: searchValue,
                operator: user,
            };
            axios.post("/userSearch", searchData)
                .then((response) => {
                    if (response.data.data && Array.isArray(response.data.data)) {
                        setTableData(response.data.data);
                    }
                })
                .catch((error) => {
                    console.error("An error occurred while retrieving data. ", error);
                });
        } else {
            axios.post("/userList", listData)
                .then((response) => {
                    if (response.data.data && Array.isArray(response.data.data)) {
                        setTableData(response.data.data);
                    }
                })
                .catch((error) => {
                    console.error("An error occurred while retrieving data. ", error);
                });
        }
    };

    const columns = [
        {
            id: 1,
            title: "Id",
            dataIndex: "id",
        },
        {
            id: 2,
            title: "Username",
            dataIndex: "username",
        },
        {
            id: 3,
            title: "Create Date",
            dataIndex: "createDate",
            render: (text) => dayjs(text).format('DD.MM.YYYY HH:mm:ss'),
        },
        {
            id: 4,
            title: "Operator",
            dataIndex: "operator",
        },
        {
            title: 'Process',
            key: 'action',
            render: (_, record) => (
                <Space size="middle">
                    <Tooltip placement="top" title="Update User">
                        <Button
                            type="primary"
                            icon={<EditOutlined />}
                            onClick={() => handleUserUpdateClick(record)}
                        />
                    </Tooltip>

                    <Tooltip placement="top" title="Delete User">
                        <Button
                            type="primary"
                            icon={<DeleteOutlined />}
                            onClick={() => handleUserDeleteClick(record)}
                        />
                    </Tooltip>
                </Space>
            ),
        },
    ];

    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedRow(selectedRows[0]);
        },
    };

    const onTableRowClick = (record) => {
        setSelectedRow(record);
    };

    useEffect(() => {
        axios.post("/userList", listData)
            .then((response) => {
                if (response.data.data && Array.isArray(response.data.data)) {
                    setTableData(response.data.data);
                }
            })
            .catch((error) => {
                console.error("An error occurred while retrieving data. ", error);
            });
    }, [token]);

    const sendRequestToUserAdd = (data) => {
        const user = JSON.parse(localStorage.authdata).username;
        const requestData = {
            username: data.username ? data.username : null,
            password: data.password ? data.password : null,
            isAdmin: data.isAdmin ? "ROLE_ADMIN" : "ROLE_VIEWER",
            operator: user
        };

        axios.post("/userAdd", { method: "userAdd", processor: "userManagement", data: requestData })
            .then((response) => {
                if (response.status === 200) {
                    setModal1Open(false);
                    successMessage();
                    refreshData();
                } else {
                    setModal1Open(false);
                    errorMessage();
                }
            })
            .catch((error) => {
                setModal1Open(false);
                errorMessage();
            });
    };

    const sendRequestToUserUpdate = (data) => {
        const user = JSON.parse(localStorage.authdata).username;
        const requestData = {
            username: data.username,
            id: selectedRow.id,
            password: data.password,
            operator: user,
        };
        axios.post("/userUpdate", { method: "userUpdate", processor: "userManagement", data: requestData })
            .then((response) => {
                if (response.status === 200) {
                    setModal1Open(false);
                    successMessageUpdate();
                    refreshData();
                } else {
                    setModal1Open(false);
                    errorMessageUpdate();
                }
            })
            .catch((error) => {
                setModal1Open(false);
                errorMessageUpdate();
            });
    };

    const sendRequestForDelete = () => {
        const user = JSON.parse(localStorage.authdata).username;
        const requestData = {
            id: selectedRow.id,
            operator: user,
        };

        axios.post("/userDelete", { method: "userDelete", processor: "userManagement", data: requestData })
            .then((response) => {
                if (response.status === 200) {
                    setModal3Open(false);
                    successMessageDelete();
                    refreshData();
                } else {
                    setModal3Open(false);
                    errorMessageDelete();
                }
            })
            .catch((error) => {
                setModal3Open(false);
                errorMessageDelete();
            });
    };

    const refreshData = () => {
        axios.post("/userList", listData)
            .then((response) => {
                if (response.data.data && Array.isArray(response.data.data)) {
                    setTableData(response.data.data);
                }
            })
            .catch((error) => {
                console.error("An error occurred while retrieving data. ", error);
            });
    };

    const handleUserAddClick = () => {
        form.resetFields(); // Formu sıfırla
        setIsUpdateMode(false); // Ekleme modu
        setModal1Open(true); // Modalı aç
    };

    const handleUserUpdateClick = (record) => {
        form.setFieldsValue(record); // Güncelleme için formu doldur
        setIsUpdateMode(true); // Güncelleme modu
        setModal1Open(true); // Modalı aç
    };

    const handleUserDeleteClick = (record) => {
        setSelectedRow(record); // Silme işleminde kullanılacak kullanıcıyı ayarla
        setModal3Open(true); // Silme modalını aç
    };

    const handleOk = () => {
        form.validateFields()
            .then((values) => {
                if (isUpdateMode) {
                    sendRequestToUserUpdate(values); // Güncelleme isteği
                } else {
                    sendRequestToUserAdd(values); // Ekleme isteği
                }
                form.resetFields(); // Formu temizle
                setModal1Open(false); // Modalı kapat
            })
            .catch((errorInfo) => {
                console.error('Form validation error.:', errorInfo);
            });
    };

    // Modalı kapatırken formu temizle
    const handleCancel = () => {
        form.resetFields(); // Formu temizle
        setModal1Open(false); // Modalı kapat
    };

    const handleDeleteOk = () => {
        sendRequestForDelete(); // Silme isteği gönder
    };

    const successMessage = () => {
        message.success('User added successfully.');
    };

    const errorMessage = () => {
        message.error('An error occurred while adding the user.');
    };

    const successMessageUpdate = () => {
        message.success('User updated successfully.');
    };

    const errorMessageUpdate = () => {
        message.error('An error occurred while updating the user.');
    };

    const successMessageDelete = () => {
        message.success('User deleted successfully.');
    };

    const errorMessageDelete = () => {
        message.error('An error occurred while deleting the user.');
    };

    return (
        <>
            {/* Kullanıcı Ekleme/Güncelleme Modalı */}
            <Modal
                title={isUpdateMode ? "User Update" : "User Add"}
                style={{ top: 20 }}
                open={modal1Open}
                onOk={handleOk}
                onCancel={handleCancel} // Modal kapatıldığında form temizlenecek
            >
                <UserAdd form={form} />
            </Modal>

            {/* Kullanıcı Silme Modalı */}
            <Modal
                title="Delete User"
                open={modal3Open} // Sadece silme modalı açılacak
                onOk={handleDeleteOk} // Silme işlemi
                onCancel={() => setModal3Open(false)} // Silme modalını kapat
            >
                <p>Are you sure you want to delete selected user?</p>
            </Modal>

            <div>
                <div style={{ textAlign: "left", display: "flex", alignItems: "center" }}>
                    <Tooltip placement="rightTop" title="Kullanıcı Ekle">
                        <Button
                            type="primary"
                            icon={<PlusCircleOutlined />}
                            onClick={handleUserAddClick} // Kullanıcı Ekle butonuna tıklayınca ekleme modu açılır
                            style={{ marginRight: '5px' }}
                        >
                            User Add
                        </Button>
                    </Tooltip>
                </div>
                <Table
                    scroll={{ x: 1000 }}

                    rowSelection={{
                        type: selectionType,
                        ...rowSelection,
                    }}
                    columns={columns}
                    dataSource={tableData.map((item) => ({ ...item, key: item.id }))}
                />

            </div>
        </>
    );
};

const UserAdd = ({ form }) => {
    return (
        <Form form={form} name="control-hooks" labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} style={{ maxWidth: 600 }}>
            <Form.Item
                name="username"
                label="Username"
                rules={[{ required: true }]}
            >
                <Input placeholder="input username" />
            </Form.Item>

            <Form.Item
                name="password"
                label="Password"
                rules={[{ required: true, message: 'Please enter your password!' }]}
            >
                <Input.Password placeholder="input password" />
            </Form.Item>

            <Form.Item
                name="againPassword"
                label="Password Again"
                dependencies={['password']}
                hasFeedback
                rules={[
                    { required: true, message: 'Please enter your password again!' },
                    ({ getFieldValue }) => ({
                        validator(_, value) {
                            if (!value || getFieldValue('password') === value) {
                                return Promise.resolve();
                            }
                            return Promise.reject('Passwords do not match!');
                        },
                    }),
                ]}
            >
                <Input.Password placeholder="input password" />
            </Form.Item>

            <Form.Item
                name="isAdmin"
                label="Admin"
                valuePropName="checked"

            >
                <Switch
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                    defaultChecked={false}
                />
            </Form.Item>
        </Form>

    );
};

export default UserDefinitionsList;
