import React, { useEffect, useState } from "react";
import { Table, Button, message, Space, Tooltip, Input, Modal, Upload, Select } from "antd";
import {
    CheckOutlined, ClockCircleOutlined, CloseSquareOutlined,
    DownloadOutlined,
    InboxOutlined, StopOutlined
} from "@ant-design/icons";
import axios from "axios";
import dayjs from 'dayjs';
import TextArea from "antd/es/input/TextArea";
import {auto} from "@popperjs/core";


const { Dragger } = Upload;
const { Option } = Select;

const FileManagement = () => {
    const [selectedRow, setSelectedRow] = useState(null);
    const [token] = useState(JSON.parse(localStorage.authdata).token);
    const [tableData, setTableData] = useState([]);
    const [companies, setCompanies] = useState([]);
    const [fileTypes, setFileTypes] = useState([]); // FileType seçenekleri için state ekledim
    const [selectedCompany, setSelectedCompany] = useState('all');
    const [selectedFileType, setSelectedFileType] = useState('all');
    const [modal1Open, setModal1Open] = useState(false);
    const [modal3Open, setModal3Open] = useState(false);
    const [approveModalOpen, setApproveModalOpen] = useState(false);
    const [rejectModalOpen, setRejectModalOpen] = useState(false); // Onay modalı için state ekledim
    const [loading, setLoading] = useState(false);
    const user = JSON.parse(localStorage.authdata).username;
    let [rejectDescription, setRejectDescription] = useState(""); // Red sebebi için state ekledim
    const [personel , setPersonel] = useState(null);

    useEffect(() => {
        fetchCompanies();
        fetchFileTypes();
        fetchData();
    }, [token]);

    useEffect(() => {
        if (!modal1Open && !modal3Open) {
            fetchData();
            console.log("fileManagement açıldı.")
        }
    }, [modal1Open, modal3Open]);

    const fetchCompanies = () => {
        axios.post('/companyList', { method: "companyList", processor: "company" })
            .then(response => {
                if (response.data.data && Array.isArray(response.data.data)) {
                    // "Hepsi" seçeneğini ekleyerek şirket listesini güncelle
                    setCompanies([{ id: 'all', name: 'all' }, ...response.data.data]);
                }
            })
            .catch((error) => {
                console.error("Data çekilirken bir hata oluştu: ", error);
            });
    };

    const fetchFileTypes = () => {
        // FileType seçeneklerini API'den çekebiliriz veya manuel olarak ekleyebiliriz.
        // Örneğin manuel olarak eklemek için:
        setFileTypes([
            { id: 'all', name: 'all' },
            { id: 'Type1', name: 'Nüfus Cüzdanı Fotokopisi' },
            { id: 'Type2', name: 'Geçici Görevlendirme Formu' },
            { id: 'Type3', name: 'İkametgah Kaydı' },
            { id: 'Type4', name: 'Adli Sicil Kaydı' },
            { id: 'Type5', name: 'SGK İşe Giriş Bildirgesi, Veya İşveren/İşçi Bağkur Belgesi' },
            { id: 'Type6', name: 'Güncel Aylık Hizmet Belgesi' },
            { id: 'Type7', name: 'Diploma ve/veya Mesleki Yeterlilik Belgesi' },
            { id: 'Type8', name: 'Ehliyet, Operatör Belgesi, SRC Belgesi ve Psikoteknik Belgesi' },
            { id: 'Type9', name: 'Sağık Raporu' },
            { id: 'Type10', name: 'Temel ISG Eğitim Sertifikası' },
            { id: 'Type11', name: 'KKD Zimmet Tutanakları Fazla Çalışma Onay Belgesi' },
            { id: 'Type12', name: 'Jandarma Bildirimi İçin Jandarma Bildirim Formu' },
            { id: 'Type13', name: 'Gece Çalışma Yapacak Personellerin, Gece Çalışmayı Kabul Ettiklerine Dair Belge' },
            { id: 'Type14', name: 'Beden İşçisi Statüsünde Çalışacak Personellerin, MYK Gerektiren İşlerde Çalıştırılmayacağına Dair Taahhüt' },
            { id: 'Type15', name: 'İsg Takip Üzerinde Yapılan İşyeri Hekimi Ve İş Güvenliği Uzmanı Atamaları' },
            { id: 'Type16', name: 'Yasal Kapsamda Hazırlanmış Risk Analizleri' },
            { id: 'Type17', name: 'Yasal Mevzuat Kapsamında Aynı Firma Çalışanlarında 10 Kişiye 1 İlkyardımcı Eğitimi Olan Personel' },

        ]);
    };


    const handlePersonelChange = (e) => {
        setPersonel(e.target.value); // Personel input alanındaki değeri günceller
    };

    const fetchData = () => {
        axios.post("/filesList", listData())
            .then((response) => {
                if (response.data.data && Array.isArray(response.data.data)) {
                    let filteredData = response.data.data;

                    // Kurum filtresi
                    if (selectedCompany !== 'all') {
                        filteredData = filteredData.filter(item => item.company && item.company.id === selectedCompany);
                    }

                    // FileType filtresi
                    if (selectedFileType !== 'all') {
                        filteredData = filteredData.filter(item => item.fileType === selectedFileType);
                    }
                    // Personel filtresi
                    if (personel) {
                        filteredData = filteredData.filter(item =>
                            item.personel && item.personel.toLowerCase().includes(personel.toLowerCase())
                        );
                    }

                    setTableData(filteredData);
                }
            })
            .catch((error) => {
                console.error("Data çekilirken bir sorun oluştu: ", error);
            });
    };

    const listData = () => {
        const data = {
            method: "filesList",
            processor: "file",
            operator: user
        };

        return data;
    };

    const handleCompanyChange = (value) => {
        setSelectedCompany(value);
    };

    const handleFileTypeChange = (value) => {
        setSelectedFileType(value);
    };

    const handlePersonel = (value) => {
        setPersonel(value);
    }
    const handleFilter = () => {
        fetchData();
    };

    const sendRequestForReject = () => {
        const requestData = {
            id: selectedRow.id,
            operator: user,
            rejectDescription: rejectDescription,
        };

        setLoading(true);

        axios
            .post("/reject", { method: "reject", processor: "files", data: requestData })
            .then((response) => {
                if (response.status === 200) {
                    message.success('Kayıt reddedildi.');
                    fetchData();
                } else {
                    message.error('Red işlemi sırasında bir sorun oluştu.');
                }
            })
            .catch((error) => {
                console.error("An error occurred while sending the reject request: ", error);
                message.error('Red işlemi sırasında bir sorun oluştu');
            })
            .finally(() => {
                setLoading(false);
                resetDeleteInput(rejectDescription)
            });
    };

    const sendRequestForApproval = () => {
        const requestData = {
            id: selectedRow.id,
            operator: user,
        };

        setLoading(true);

        axios
            .post("/approved", { method: "approve", processor: "files", data: requestData })
            .then((response) => {
                if (response.status === 200) {
                    message.success('Onaylama işlemi başarılı');
                    fetchData();
                } else {
                    message.error('Onay işlemi sırasında bir sorun oluştu.');
                }
            })
            .catch((error) => {
                console.error("An error occurred while sending the approval request: ", error);
                message.error('Onay işlemi sırasında bir sorun oluştu.');
            })
            .finally(() => {
                setLoading(false);
                setApproveModalOpen(false);
            });
    };

    const sendRequestForDownload = () => {
        const requestData = {
            id: selectedRow.id,
            operator: user,
        };

        setLoading(true); // Set loading to true

        axios
            .post("/download", { method: "download", processor: "files", data: requestData })
            .then((response) => {
                if (response.status === 200) {
                    const path = response.data.data.path;
                    const formattedPath = path.replace('/home/isg/repo/', 'http://79.110.51.253/rest-api/download/');
                    window.open(formattedPath, "_blank");
                    setModal3Open(false);
                    successMessage();
                } else {
                    errorMessage();
                }
            })
            .catch((error) => {
                console.error("An error occurred while sending the request: ", error);
                errorMessage();
            })
            .finally(() => {
                setLoading(false); // Set loading to false after the request is done
            });
    };

    const columns = [
        {
            id: 3,
            title: "Belge Türü",
            dataIndex: "fileType",
            render: (fileType,item) => {
                if(item.fileType === 'Type1' ) {
                    return 'Nüfus Cüzdanı Fotokopisi';
                } else if(item.fileType === 'Type2'){
                    return 'Geçici Görevlendirme Formu';
                } else if(item.fileType === 'Type3'){
                    return 'İkametgah Kaydı';
                } else if(item.fileType === 'Type4'){
                    return 'Adli Sicil Kaydı';
                } else if(item.fileType === 'Type5'){
                    return 'SGK İşe Giriş Bildirgesi, Veya İşveren/İşçi Bağkur Belgesi';
                } else if(item.fileType === 'Type6'){
                    return 'Güncel Aylık Hizmet Belgesi';
                } else if(item.fileType === 'Type7'){
                    return 'Diploma ve/veya Mesleki Yeterlilik Belgesi';
                } else if(item.fileType === 'Type8'){
                    return 'Ehliyet, Operatör Belgesi, SRC Belgesi ve Psikoteknik Belgesi';
                } else if(item.fileType === 'Type9'){
                    return 'Sağık Raporu';
                } else if(item.fileType === 'Type10'){
                    return 'Temel ISG Eğitim Sertifikası';
                } else if(item.fileType === 'Type11'){
                    return 'KKD Zimmet Tutanakları Fazla Çalışma Onay Belgesi';
                } else if(item.fileType === 'Type12'){
                    return 'Jandarma Bildirimi İçin Jandarma Bildirim Formu';
                } else if(item.fileType === 'Type13'){
                    return 'Gece Çalışma Yapacak Personellerin, Gece Çalışmayı Kabul Ettiklerine Dair Belge';
                } else if(item.fileType === 'Type14'){
                    return 'Beden İşçisi Statüsünde Çalışacak Personellerin, MYK Gerektiren İşlerde Çalıştırılmayacağına Dair Taahhüt';
                } else if(item.fileType === 'Type15'){
                    return 'İsg Takip Üzerinde Yapılan İşyeri Hekimi Ve İş Güvenliği Uzmanı Atamaları';
                } else if(item.fileType === 'Type16'){
                    return 'Yasal Kapsamda Hazırlanmış Risk Analizleri';
                } else if(item.fileType === 'Type17'){
                    return 'Yasal Mevzuat Kapsamında Aynı Firma Çalışanlarında 10 Kişiye 1 İlkyardımcı Eğitimi Olan Personel';
                }
            },
        },
        {
            id: 4,
            title: "Kurum",
            dataIndex: "operator"
        },
        {
            id: 5,
            title: "Personel",
            dataIndex: "personel"
        },
        {
            id: 6,
            title: "Süresi Geçti",
            dataIndex: "timeout",
            render: (timeout) => timeout ? <CheckOutlined /> : "",
        },
        {
            id: 7,
            title: "Onay Durumu",
            dataIndex: "approved",
            render: (reject,item) => {
                if(item.reject === true ) {
                    return null;
                } else {
                    if (item.approved === null ) {
                        return <ClockCircleOutlined />;
                    } else if (item.approved === true) {
                        return <CheckOutlined />;
                    } else if(item.approved === false) {
                        return "";
                    }
                }
            },
        },
        {
            id: 8,
            title: "Ret Durumu",
            dataIndex: "reject",
            render: (reject,item) => {
                if (item.reject === null ) {
                    return "";
                } else if (item.reject === true) {
                    return <CheckOutlined  />;
                } else {
                    return "";
                }
            },
        },
        {
            id: 9,
            title: "Ret Nedeni",
            dataIndex: "rejectDescription",
            render: (rejectDescription,item) => {
                if(item.reject===true) {
                    if (item.rejectDescription === null) {
                        return null;
                    } else{
                        return item.rejectDescription;
                    }
                } else {
                    return null;
                }
            },
        },
        {
            id: 10,
            title: "Oluşturma Tarihi",
            dataIndex: "createDate",
            render: (text) => text !== null ? dayjs(text).format('DD.MM.YYYY HH:mm:ss') : "",
        },
        {
            id: 11,
            title: "Başlangıç Tarihi",
            dataIndex: "startDate",
            render: (text) => text !== null ? dayjs(text).format('DD.MM.YYYY HH:mm:ss') : "",
        },
        {
            id: 12,
            title: "Bitiş Tarihi",
            dataIndex: "endDate",
            render: (text) => text !== null ? dayjs(text).format('DD.MM.YYYY HH:mm:ss') : "",
        },
        {
            id: 13,
            title: "Operator",
            dataIndex: "operator",
        },
        {
            title: 'İşlemler',
            key: 'action',
            render: (_, record) => (
                <Space size="middle">
                    <Tooltip placement="top" title="İndir">
                        <Button
                            type="primary"
                            icon={<DownloadOutlined />}
                            onClick={() => setModal3Open(true)}
                        />
                    </Tooltip>
                    <Tooltip placement="top" title="Onayla">
                        <Button
                            type="primary"
                            icon={<CheckOutlined />}
                            onClick={() => setApproveModalOpen(true)}
                            style={{ backgroundColor: 'green', borderColor: 'green' }}
                            disabled={record.reject === true || record.approved === true}
                        />
                    </Tooltip>
                    <Tooltip placement="top" title="Ret">
                        <Button
                            type="primary"
                            icon={<CloseSquareOutlined />}
                            onClick={() => setRejectModalOpen(true)}
                            style={{ backgroundColor: 'red', borderColor: 'red' }}
                            disabled={record.reject === true || record.approved === true}
                        />
                    </Tooltip>
                </Space>
            ),
        },
    ];

    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedRow(selectedRows[0]);
            console.log('Selected Row:', selectedRows[0]);
        },
    };

    const onTableRowClick = (record) => {
        setSelectedRow(record);
    };

    const handleOk = () => {
        sendRequestForDownload();
        setSelectedRow(null);
    };

    const resetDeleteInput = () => {
        setRejectModalOpen(false)
        setRejectDescription(null)
    };

    const handleCancel = () => {
        setSelectedRow(null);
        setModal3Open(false);
    };

    const successMessage = () => {
        message.success('Dosya indirildi.');
    };

    const errorMessage = () => {
        message.error('Dosya indirme işlemi sırasında bir sorun oluştu.');
    };

    const uploadProps = {
        name: 'file',
        multiple: true,
        customRequest: async ({ file, onSuccess, onError }) => {
            const formData = new FormData();
            formData.append('file', file);
            formData.append('operator', user);

            try {
                const response = await axios.post('/uploadTemp', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });
                onSuccess(response.data);
                message.success(`${file.name} Yükleme işlemi başarılı.`);
                fetchData();
            } catch (error) {
                onError(error);
                message.error(`Yükleme işlemi sırasında bir sorun oluştu ${file.name}.`);
            }
        },
        onChange(info) {
            const { status } = info.file;
            if (status !== 'uploading') {
                console.log(info.file, info.fileList);
            }
            if (status === 'done') {
                message.success(`${info.name} yükleme işlemi başarılı`);
                fetchData();
            } else if (status === 'error') {
                message.error(`Yükleme işlemi sırasında bir sorun oluştu ${info.name}.`);
            }
        },
        onDrop(e) {
            console.log('Dropped files', e.dataTransfer.files);
        },
    };

    const rowClassName = (record) => {
        if (record.timeout) {
            return 'row-timeout'; // timeout true ise renklendir
        } else if (record.reject) {
            return 'row-reject'; // reject true ise farklı renklendir
        }else if(record.approved){
            return 'row-approved';
        }
        return ''; // Hiçbir duruma uymuyorsa, normal satır
    };

    return (
        <>
            <Modal
                title="Ret Nedeni"
                open={rejectModalOpen}
                onOk={sendRequestForReject}
                onCancel={() => resetDeleteInput(rejectDescription)}
                confirmLoading={loading}
            >
                <p>Seçilen kayıt ret edilecektir. Onaylıyor musunuz?</p>
                <TextArea
                    value={rejectDescription}
                    onChange={(e) => setRejectDescription(e.target.value)}
                    rows={4}
                    placeholder="Ret nedenini yazınız."
                />
            </Modal>
            <Modal
                title="Dosya Onayı"
                open={approveModalOpen}
                onOk={sendRequestForApproval}
                onCancel={() => setApproveModalOpen(false)}
                confirmLoading={loading}
            >
                <p>Seçilen kaydı onaylanacaktır. Onaylıyormusunuz</p>
            </Modal>
            <Modal
                title="Seçilen kayıt indirilecektir. İndirmek istiyormusunuz?"
                open={modal3Open}
                onOk={handleOk}
                onCancel={handleCancel}
                confirmLoading={loading}
            >
            </Modal>
            <Modal
                title="Dosya Yükle"
                visible={modal1Open}
                onOk={() => setModal1Open(false)}
                onCancel={() => setModal1Open(false)}
                footer={null}
            >
                <Dragger {...uploadProps}>
                    <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">Click or drag file to this area to upload</p>
                    <p className="ant-upload-hint">
                        Support for a single or bulk upload. Strictly prohibited from uploading company data or other banned files.
                    </p>
                </Dragger>
            </Modal>
            <div>
                <Table
                    rowSelection={{
                        type: "radio",
                        ...rowSelection,
                    }}
                    columns={columns}
                    dataSource={tableData.map((item) => ({...item, key: item.id}))}
                    onRow={(record) => ({
                        onClick: () => onTableRowClick(record),
                    })}
                    scroll={{ x: 1000 }}
                    // rowClassName={rowClassName}
                    pagination={{
                        pageSize: 5, // Görüntülenen kayıt sayısını 5 ile limitler
                    }}
                    style={{ width: 'auto', height: '600px' }}
                />
                <div style={{display: 'flex', flexDirection: 'column', gap: 'auto', marginTop: 'auto'}}>
                    <div style={{display: 'flex', flexDirection: 'column'}}>
                        <div style={{fontWeight: 'bold', marginBottom: '8px'}}>
                            Company
                        </div>
                        <Select
                            placeholder="Kurum seçin"
                            value={selectedCompany}
                            onChange={handleCompanyChange}
                            style={{width: 'auto'}}
                        >
                            {companies.map(company => (
                                <Option key={company.id} value={company.id}>
                                    {company.name}
                                </Option>
                            ))}
                        </Select>
                    </div>

                    <div style={{display: 'flex', flexDirection: 'column'}}>
                        <div style={{fontWeight: 'bold', marginBottom: '8px', marginTop:'8px'}}>
                            Belge Türü
                        </div>
                        <Select
                            placeholder="Dosya Türü seçin"
                            value={selectedFileType}
                            onChange={handleFileTypeChange}
                            style={{width: '100%'}}
                        >
                            {fileTypes.map(type => (
                                <Option key={type.id} value={type.id}>
                                    {type.name}
                                </Option>
                            ))}
                        </Select>
                    </div>

                    <div style={{display: 'flex', flexDirection: 'column'}}>
                        <div style={{fontWeight: 'bold', marginBottom: '8px',marginTop: '8px'}}>
                            Personel
                        </div>
                        <Input
                            style={{width: '100%'}}
                            value={personel}
                            onChange={handlePersonelChange} // Personel alanındaki değeri izler
                        />
                    </div>

                    <Button type="primary" onClick={handleFilter} style={{alignSelf: 'center',marginTop:'7px'}}>
                        Filtrele
                    </Button>
                </div>

            </div>
        </>
    );
};

export default FileManagement;
