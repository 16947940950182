import React, {Component, useContext, useEffect, useState} from 'react';
import {BrowserRouter as Router, Navigate, Redirect, Route, Routes} from 'react-router-dom';
import HeaderComponent from '../login/HeaderComponent';
import Login from './auth/Login';

import FooterComponent from '../login/FooterComponent';
import Dashboard from "./Dashboard";
import {MainContext} from "../context";
import ForgotPassword from "./auth/ForgotPassword";
import axiosSetup from "../interceptors/axiosinterceptor";

import {
    MenuFoldOutlined,
    MenuUnfoldOutlined,
    UploadOutlined,
    UserOutlined,
    VideoCameraOutlined,
} from '@ant-design/icons';
import { Layout, Menu, Button, theme } from 'antd';
const { Header, Sider, Content } = Layout;
const ISG = ({authData}) => {


    const [value, dispatch] = useContext(MainContext);
    const [contextLoaded, setContextLoaded] = useState(false);

    useEffect(() => {

        if (authData != null && authData.signedIn) {
            dispatch({
                "type": "SIGN_IN", payload: authData,
            });
        } else {
            dispatch({
                "type": "SIGN_IN", payload: {signedIn : false},
            });
        }

        }, [authData])

    useEffect(() => {
        setContextLoaded(true);
        axiosSetup();
    }, [value.authData.signedIn]);



        return (
            <>
            {contextLoaded ? <div className="isg">
                    <Router>
                        { !value.authData.signedIn ? <>
                                <Routes>
                                    <Route path="/forgot" exact element={<ForgotPassword />} />

                                    <Route path="*" exact element={<Login />} />
                                </Routes>
                            </> :
                            <>
                                <Routes>
                                    <Route path="/" exact element={<Dashboard />} />
                                </Routes>
                            </>
                        }
                    </Router>
                </div> :
                    <span>loading</span>
            }
            </>
        );




}

export default ISG;
